import ChefError from '../components/Error/ChefError';

export default function Custom404() {
  return (
    <ChefError
      title="404"
      subtitle={`Sorry, we can't find the page you are looking for!`}
    />
  );
}
